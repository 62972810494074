// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-musings-js": () => import("../src/templates/musings.js" /* webpackChunkName: "component---src-templates-musings-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animations-js": () => import("../src/pages/animations.js" /* webpackChunkName: "component---src-pages-animations-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-javascript-js": () => import("../src/pages/javascript.js" /* webpackChunkName: "component---src-pages-javascript-js" */),
  "component---src-pages-metrics-js": () => import("../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */)
}

